<template>
	<div class="detail">
		<div class="main">

			<div class="pic">
				<div class="big">
					<div id="detail_main"
						:style="'background-image: url('+(detail.pics ? detail.pics[album_index]:'')+');'">
						<div class="musk"></div>
						<div class="mirror">
							<img :src="detail.pics ? detail.pics[album_index]:''" width="100%" alt="" />
						</div>
					</div>

					<div class="pic-button-next" :class="{'d':album_index==0}" @click="()=>{
						if(album_index -1 >=0){
							album_index--;
						}
						}">
						<i class="el-icon-arrow-left"></i>
					</div>
					<div class="pic-button-prev" :class="{'d':detail.pics && album_index==detail.pics.length-1}" @click="()=>{
						if(album_index +1 < detail.pics.length){
							album_index++;
						}
						}">
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
				<div class="album">
					<Album @showIt="showIt" :data="detail.pics" :activeIndex="album_index"></Album>
				</div>
				<div class="label">
					<div class="name"><span class="iconfont icon-label"></span>Canguro</div>
					<div class="title">Exterior</div>
					<div class="content">{{ detail.exterior }}</div>
					<div class="title">Cuidado de la prenda</div>
					<div class="content">
						<img v-for="val in detail.cuidado" :src="val.icon" />
					</div>
				</div>
			</div>
			<div class="goodsInfo">
				<div class="name">{{ detail.spuName }}</div>
				<div class="price">
					<span class="current"
						:style="{'color':detail.originPrice== 0?'#000000':'red'}">€{{ detail.price }}</span>
					<span class="origin">{{
            detail.originPrice == "0.00" ? "" : "€" + detail.originPrice
          }}</span>
				</div>
				<!-- <div class="title">颜色:</div>
				<div class="color">
					<div
						v-for="val in color.list"
						:class="`${val.id == color.current.id ? 'active' : ''} item`"
						:style="`background: ${val.color};`"
						@click="color.current = val"
					></div>
				</div>
				<div class="line"></div> -->
				<!-- <template v-for="val in detail.specs">
					<div class="title">{{val.specName}}:</div>
					<div class="sizes">
						<div class="item" v-for="v in val.specs">{{v.value}}</div>
					</div>
					<div class="line"></div>
				</template> -->
				<div class="title">{{ $t("goodDetail.specification") }}:</div>
				<div class="sizes" v-if="detail.specs && detail.specs.length != 0">
					<div v-for="(val, index) in calcSpaces(detail.specs)" :key="index"
						:class="`item ${active.skuId == (detail.skus[detail.skus.findIndex((node)=>{ return node.specMode == val.map((item)=>{return item.mode;}).join('');})]? detail.skus[detail.skus.findIndex((node)=>{ return node.specMode == val.map((item)=>{return item.mode;}).join('');})].skuId:'') ? 'active' : ''} border`"
						@click="()=>{
							let activeItem = detail.skus[detail.skus.findIndex((node)=>{ return node.specMode == val.map((item)=>{return item.mode;}).join('');})];
							
							if(activeItem){ active.skuId=activeItem.skuId; active.price=activeItem.price; }
						}">
						{{ val.map((item)=>{return item.value;}).join(" ") }}
					</div>
				</div>
				<!-- 		<div class="line"></div> -->
				<div class="buttons">
					<div class="btn deep" @click="addCart">{{ $t("goodDetail.addCart") }}</div>
					<div class="btn" @click="$bus.$emit('addLike', detail.spuId)">
						{{ $t("goodDetail.addCollect") }}
					</div>
				</div>
				<div class="title">{{ $t("tip.share") }}:</div>
				<div class="share">
					<img class="item" src="../../assets/icon/facebook.png" @click="handleFaceBook()" />
					<img class="item" src="../../assets/icon/camera.png" @click="handleCamera()" />
					<!-- <img class="item" src="../../assets/icon/twitter.png" /> -->
				</div>
				<div class="title">{{ $t("goodDetail.detail") }}:</div>
				<div class="content" v-html="detail.content"></div>
				<div class="content" style="padding-top: 20px;" v-html="(detail.ingredients||'')"></div>
				<el-collapse>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/cloth.png" />
								<span>{{ $t("tip.washTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-for="(item, index) in instructions" :key="index" v-html="item.content"></div>
						</div>
					</el-collapse-item>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/car.png" />
								<span>{{ $t("tip.postTip") }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<div v-for="(item, index) in distribution" :key="index" v-html="item.content"></div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<div class="hot">
			<div class="title">{{ $t("tip.detailRecommend") }}</div>
			<div class="goods">
				<div class="item" v-for="val in recommand">
					<GoodsItem :detail="val"></GoodsItem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Album from "./album.vue";
	import Api from "../../api/request.js";
	import GoodsItem from "../../components/Goods/listItem.vue";
	export default {
		name: "GoodsDetail",
		components: {
			GoodsItem,
			Album,
		},
		watch: {
			$route(res) {
				if (res.path == "/GoodsDetail") {
					this.getDetail();
				}
			},
		},
		data() {
			return {
				color: {
					list: [{
							id: 1,
							color: "#000000"
						},
						{
							id: 2,
							color: "#E0E4E3"
						},
						{
							id: 3,
							color: "#7D5337"
						},
						{
							id: 4,
							color: "#BE2B35"
						},
					],
					current: {
						id: 1,
						color: "#000000"
					},
				},
				size: {
					list: [{
							id: 1,
							size: "XS"
						},
						{
							id: 2,
							size: "S"
						},
						{
							id: 3,
							size: "M"
						},
						{
							id: 4,
							size: "L"
						},
						{
							id: 5,
							size: "XL"
						},
					],
					current: {
						id: 1,
						color: "XS"
					},
				},
				detail: {},
				album_index: 0,
				active: {
					skuId: "",
				},
				recommand: [],
				instructions: "", //洗衣指南
				distribution: "", // 配送
			};
		},
		created() {
			this.getDetail();
		},
		methods: {
			//规格排列组合
			calcSpaces(transArr) {
				let resultArr = [];

				function get(array, index, val) {
					if (!array[index]) {
						resultArr.push(val);
						return;
					};

					array[index].specs.forEach((v, i) => {
						get(array, index + 1, index === 0 ? [v] : [...val, v])
					})
				}
				get(transArr, 0);
				return resultArr;
			},
			picLupa() {
				//获取元素-------------------------
				const pro = document.querySelector('#detail_main');
				const musk = document.querySelector('.musk');
				const mirror = document.querySelector('.mirror');
				//添加鼠标移入移出事件------------------
				pro.onmouseenter = function() {
					musk.style.display = 'block';
					mirror.style.display = 'block';
				}
				pro.onmouseleave = function() {
					musk.style.display = 'none';
					mirror.style.display = 'none'
				}
				//给商品框添加鼠标移动事件--------------
				/*slider随着鼠标移动在父元素的空间范围内移动，我们要获取到鼠标在元素pro上的位置。
				实际中我们的项目页面肯定不止一个放大镜这么简单，这里我们获取鼠标的位置采用以下公式：
				鼠标在元素上的位置=鼠标在视口上的位置-元素在视口上的位置.*/
				pro.onmousemove = function(event) {
					//鼠标在视口上的位置
					let clientX = event.clientX;
					let clientY = event.clientY;
					//元素在视口中的位置
					let eleX = pro.getBoundingClientRect().left;
					let eleY = pro.getBoundingClientRect().top;
					//鼠标在元素上的位置
					let left = clientX - eleX - musk.offsetWidth / 2; //此时鼠标在musk的中心位置
					let top = clientY - eleY - musk.offsetHeight / 2; //此时鼠标在musk的中心位置
					//左右边界判断
					if (left <= 0) {
						left = 0;
					} else if (left >= pro.clientWidth - musk.offsetWidth) {
						left = pro.clientWidth - musk.offsetWidth;
					}
					//上下边界值判断
					if (top <= 0) {
						top = 0;
					} else if (top > pro.clientHeight - musk.offsetHeight) {
						top = pro.clientHeight - musk.offsetHeight;
					}
					//设置musk的位置
					musk.style.left = left + 'px';
					musk.style.top = top + 'px';
					/*设置mirro中的图片随着musk的移动而移动
					 我们发现mirror中图片的移动是和musk的移动方向是相反的
					**/
					//这里使用了scrollLeft和scrollTop非常方便快捷
					mirror.scrollLeft = left * 2;
					mirror.scrollTop = top * 2;
				}
			},
			getDetail() {
				this.$dialog.loading();
				Promise.all([
					new Promise((resolve, reject) => {
						Api.Goods.detail({
							spuId: this.$route.query.id
						}).then((res) => {
							this.detail = res.data.data;
							//这里占时处理尺码排序,如果有问题,只能后端处理
							if (this.detail.skus.length != 0) {
								this.detail.skus = this.detail.skus.sort((a, b) => {
									return a.specInfo.localeCompare(b.specInfo,
										'zh-CN', {
											numeric: true
										});
								});
								console.log("sort")
							}

							this.detail.pics.unshift(this.detail.cover);
							Api.Goods.recommand({
								spu_ids: res.data.data.recommendSpu
							}).then((res) => {
								this.recommand = res.data.data;
								resolve();
							});

							this.picLupa();
						});
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.article({
							page: 1,
							pageLimit: 10,
							articleMenuId: 3,
						}).then((res) => {
							this.instructions = res.data.data.list;
							resolve();
						});
					}),
					new Promise((resolve, reject) => {
						Api.AboutUs.article({
							page: 1,
							pageLimit: 10,
							articleMenuId: 4,
						}).then((res) => {
							this.distribution = res.data.data.list;
							resolve();
						});
					}),
				]).then((res) => {
					this.$dialog.close();
				});
			},
			addCart() {
				if (this.active.skuId == "") {
					if (this.detail.skus && this.detail.skus.length != 0) {
						this.$bus.$emit("addCart", this.detail.skus[0].skuId, this.detail);
					} else {

					}
				} else {
					this.$bus.$emit("addCart", this.active.skuId, this.detail);
				}
			},
			showIt(url, index) {
				//this.detail.cover = url;
				this.album_index = index;
			},
			handleFaceBook() {
				window.location.href =
					"https://www.facebook.com/Newness-kids-fashion-100307315076919";
			},
			handleCamera() {
				window.location.href = "https://www.instagram.com/newness_kids/";
			},
		},
	};
</script>

<style lang="less" scoped>
	.cate {
		padding: 0 46px;
		height: 60px;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;

		.devide {
			height: 13px;
			width: 1px;
			background: #000;
			margin: 0 1em;
		}
	}

	.main {
		width: 1300px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;




		.pic {
			width: 600px;



			.big {
				height: 690px;
				position: relative;


				#detail_main {
					position: relative;
					height: 600px;
					width: 600px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}

				.mirror {
					height: 600px;
					width: 600px;
					position: absolute;
					border: 1px solid #ccc;
					right: -610px;
					top: 0;
					display: none;
					overflow: hidden;
				}

				.mirror img {
					height: 1200px;
					width: 1200px;
				}

				.musk {
					position: absolute;
					width: 150px;
					/*这里蒙版的高度要和detail_main之间成2倍关系*/
					height: 150px;
					background-color: antiquewhite;
					opacity: .5;
					display: none;
				}


				.pic-button-next,
				.pic-button-prev {
					position: absolute;
					top: 0;
					height: 100%;
					line-height: 40;

					i {
						font-size: 80px;
						color: #123178;
					}

					&.d {
						i {
							color: #cacaca;
						}
					}
				}

				.pic-button-next {
					left: 0;

				}

				.pic-button-prev {
					right: 0;

				}
			}

			.album {
				overflow: hidden;
				//height: 150px;
				margin-top: 20px;
			}

			.label {
				margin-top: 20px;

				.name {
					font-size: 14px;
					font-weight: bold;
					display: flex;
					align-items: center;
					color: #666;

					&>span {
						font-size: 18px;
						margin-right: 5px;
					}
				}

				.title {
					font-size: 14px;
					color: #123178;
					margin-top: 10px;
				}

				.content {
					font-size: 15px;
					color: #333;
					display: flex;

					&>img {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						background: #ccc;
						margin-right: 12px;
						object-fit: cover;
					}
				}
			}
		}

		.goodsInfo {
			width: 600px;

			.name {
				font-size: 28px;
				font-weight: bold;
				line-height: 40px;
			}

			.title {
				height: 100px;
				font-size: 16px;
				line-height: 100px;
				font-weight: bold;
			}

			.line {
				height: 1px;
				background: #757575;
				margin-top: 20px;
			}

			.price {
				margin: 20px 0 40px;

				.current {
					font-size: 18px;
					font-weight: bold;
					line-height: 25px;
				}

				.origin {
					font-size: 14px;
					font-weight: 400;
					line-height: 20px;
					margin-left: 18px;
					text-decoration: line-through;
				}
			}

			.color {
				display: flex;

				.item {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					margin-right: 40px;
					cursor: pointer;

					&.active {
						&::before {
							content: "";
							display: block;
							width: 24px;
							height: 24px;
							border: 4px solid #fff;
							border-radius: 50%;
							margin: 2px auto 0;
						}
					}
				}
			}

			.sizes {
				display: flex;
				flex-wrap: wrap;

				.item {
					font-size: 18px;
					font-weight: bold;
					line-height: 25px;
					margin-right: 40px;
					padding: 5px 10px;
					border-radius: 6px;
					cursor: pointer;
					margin-bottom: 15px;

					&.border {
						border: 1px solid #123178;
					}

					&.active {
						background: #123178;
						color: #fff;
					}
				}

				.item:hover {
					background: #a1b1e5;
					color: #fff;
				}
			}

			.buttons {
				display: flex;
				margin-top: 40px;

				.btn {
					height: 48px;
					padding: 0 50px;
					background: #ffffff;
					border-radius: 6px;
					border: 1px solid #123178;
					text-align: center;
					line-height: 48px;
					font-size: 12px;
					font-weight: 600;
					color: #123178;
					cursor: pointer;
					margin-right: 40px;

					&.deep {
						background-color: #e8c9a0;
						color: #fff;
						border: 1px solid #e8c9a0;
					}

					&:hover {
						background-color: #efefef;
					}
				}

				.deep:hover {
					background-color: #eecc77;
				}
			}

			.share {
				display: flex;

				.item {
					width: 24px;
					height: 24px;
					margin-right: 20px;
					cursor: pointer;
				}
			}

			.content {
				&>p {
					margin-bottom: 20px;
					font-size: 14px;
					color: #333;
				}
			}

			.tip {
				display: flex;
				align-items: center;
				// margin-top: 40px;
				cursor: pointer;

				&>img {
					width: 20px;
					height: 18px;
				}

				&>span {
					margin: 0 24px 0 16px;
					font-size: 14px;
					font-weight: 400;
				}

				&>i {
					font-size: 12px;
					font-weight: bolder;
				}
			}
		}
	}

	/deep/.el-collapse {
		border: none;

		.el-collapse-item {
			margin-top: 20px;

			.el-collapse-item__header {
				border-bottom: none;

				.el-collapse-item__arrow {
					display: none;
				}
			}
		}

		.el-collapse-item__wrap {
			border-bottom: none;
		}

		.el-collapse-item__content {
			padding-top: 20px;
		}
	}

	.hot {
		padding-left: 7px;
		overflow: hidden;

		.title {
			font-size: 28px;
			font-weight: bold;
			line-height: 40px;
			margin: 60px 0 40px;
		}

		.goods {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;

			.item {
				width: calc((100vw - 40px) / 4);
				margin-right: 6px;
				margin-top: 15px;
			}

			@media screen and (max-width: 650px) {
				.item {
					width: calc((100vw - 40px) / 2);
					margin-right: 6px;
					margin-top: 15px;
				}
			}
		}
	}
</style>
